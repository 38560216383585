<template>
    <v-app>
        <app-navigation v-if="!hideNav && isAuthenticated" />
        <v-main>
            <router-view />
        </v-main>
        <app-snackbar></app-snackbar>
        <update-snackbar></update-snackbar>
    </v-app>
</template>

<script>
import AppNavigation from '@/components/AppNavigation';
import AppSnackbar from '@/components/AppSnackbar';
import UpdateSnackbar from '@/components/AppUpdateSnackbar';
import { mapGetters } from 'vuex';

export default {
    name: 'App',
    components: { AppSnackbar, UpdateSnackbar, AppNavigation },
    watch: {
        darkMode: function () {
            this.$vuetify.theme.dark = this.darkMode;
        },
    },
    computed: {
        ...mapGetters(['getUserPreferenceValueByKey']),
        isAuthenticated() {
            return this.$store.state.isAuthenticated;
        },
        hideNav() {
            return this.$store.state.hideNav;
        },
        darkMode() {
            return this.$store.state.userPreferences.darkMode === 'true';
        },
    },
    mounted() {
        this.$vuetify.theme.dark = this.darkMode;
    },
};
</script>

<style scoped>
.theme--light.v-application {
    background-color: #eee;
}
>>> .theme--dark.v-tabs-items {
    background-color: unset;
}
>>> .v-data-table table {
    padding: 4px 0 8px;
}
>>> .v-data-table tr {
    transition: all 150ms ease-in-out;
}
>>> .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:hover:not(.v-data-table__expanded__content):not(
        .v-data-table__empty-wrapper
    ) {
    background: white;
}
>>> .v-data-table table tbody tr:hover {
    box-shadow: 0 3px 12px -1px rgba(0, 0, 0, 0.15);
    transform: translateY(-4px);
}
</style>
