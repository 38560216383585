<template>
    <v-avatar color="teal" :size="size">
        <v-img
            :src="profilePicturePath"
            alt="Avatar"
            position="top center"
            v-if="profilePicturePath != ''"
            @error="showInitials = true"
        />
        <span
            v-if="showInitials || profilePicturePath == ''"
            class="white--text headline"
            >{{ initials }}</span
        >
    </v-avatar>
</template>

<script>
export default {
    name: 'ProfileAvatar',
    props: {
        user: {
            type: Object,
            required: true,
            default: () => {
                return {
                    user: {
                        username: '',
                        attributes: {
                            email: '',
                            given_name: '',
                            family_name: '',
                            picture: '',
                        },
                    },
                };
            },
        },
        size: {
            type: String,
            required: false,
            default: '48',
        },
    },
    data() {
        return {
            showInitials: false,
        };
    },
    computed: {
        initials() {
            let firstName = this.user.attributes.given_name || '';
            let lastName = this.user.attributes.family_name || '';

            if (firstName != '' && lastName != '') {
                return firstName.charAt(0) + lastName.charAt(0);
            }

            return this.user.attributes.email.charAt(0).toUpperCase();
        },
        profilePicturePath() {
            let pictureAttribute = this.user.attributes.picture;

            if (pictureAttribute != null) {
                return pictureAttribute;
            }
            return '';
        },
    },
};
</script>
