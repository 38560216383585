import Vue from 'vue';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import router from './router';
import store from './store';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import awsCognito from '@/aws-exports';
import Constants from '@/plugins/constants';
import * as GmapVue from 'gmap-vue';
import './registerServiceWorker';
import './filters';

Amplify.configure(awsCognito);
Auth.configure(awsCognito);

Vue.config.productionTip = false;
Vue.config.devtools = true;

Vue.use(Constants);

Vue.use(GmapVue, {
    load: {
        key: 'AIzaSyDkRCpsbbD01xQM9jw-ztKjGu1I_kFcd2w',
        libraries: 'places',
    },
    installComponents: true,
});

let app;

const initialize = () => {
    if (!app) {
        app = new Vue({
            vuetify,
            router,
            store,
            render: (h) => h(App),
        }).$mount('#app');
    }
};

Auth.currentAuthenticatedUser()
    .then((cognitoUser) => {
        store.commit('setCognitoUser', cognitoUser);
        store.commit('setIsAuthenticated', true);
        store.dispatch('initialize').then(() => {
            initialize();
        });
    })
    .catch((error) => {
        store.commit('setCognitoUser', null);
        store.commit('setIsAuthenticated', false);
        console.log('Persist error: ' + error);
        initialize();
    });
