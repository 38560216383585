<template>
    <v-snackbar v-model="show" timeout="4000">
        {{ message }}
        <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="show = false">
                Close
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    name: 'AppSnackbar',
    data() {
        return {
            show: false,
            message: '',
        };
    },
    created: function () {
        this.$store.watch(
            (state) => state.snackbarMessage,
            () => {
                const msg = this.$store.state.snackbarMessage;
                if (msg !== '') {
                    this.show = true;
                    this.message = msg;
                    this.$store.commit('setSnackbarMessage', '');
                }
            }
        );
    },
};
</script>
