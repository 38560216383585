export default {
    getInternalBaseUrl: () => {
        return 'https://corehub.app';
    },
    getInternalApiPrefix: () => {
        return process.env.NODE_ENV == 'development' ? '/api/dev/' : '/api/v1/';
    },
    getClearentApi: () => {
        return process.env.NODE_ENV == 'development'
            ? 'https://boarding-sb.clearent.net/'
            : 'https://boarding.clearent.net/';
    },
    getInternalReferenceApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalApiPrefix +
            'references'
        );
    },
    getInternalProductFamilyApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalApiPrefix +
            'product-family'
        );
    },
    getInternalProductApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalApiPrefix +
            'product'
        );
    },
    getInternalIndustryApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalApiPrefix +
            'industry'
        );
    },
    getInternalProspectSourceApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalApiPrefix +
            'prospect-source'
        );
    },
    getInternalBusinessApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalApiPrefix +
            'business'
        );
    },
    getInternalBusinessContactApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalApiPrefix +
            'business-contact'
        );
    },
    getInternalBusinessLocationApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalApiPrefix +
            'business-location'
        );
    },
    getInternalBusinessProductApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalApiPrefix +
            'business-product'
        );
    },
    getInternalBusinessProductRevenueApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalApiPrefix +
            'business-product-revenue'
        );
    },
    getInternalBusinessTouchpointApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalApiPrefix +
            'business-touchpoint'
        );
    },
    getInternalUserApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl + getters.getInternalApiPrefix + 'user'
        );
    },
    getInternalSalespersonApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalApiPrefix +
            'salesperson'
        );
    },
    getInternalDnsInfoApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalApiPrefix +
            'dns-info'
        );
    },
    getInternalSendgridInfoApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalApiPrefix +
            'sendgrid-info'
        );
    },
    getInternalPermissionApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalApiPrefix +
            'permission'
        );
    },
    getInternalUserPermissionApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalApiPrefix +
            'user-permission'
        );
    },
    getInternalUserPreferenceApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalApiPrefix +
            'user-preference'
        );
    },
    getInternalTagApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl + getters.getInternalApiPrefix + 'tag'
        );
    },
    getInternalBusinessTagApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalApiPrefix +
            'business-tag'
        );
    },
    getInternalNotificationApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalApiPrefix +
            'notification'
        );
    },
    getInternalUserNotificationApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalApiPrefix +
            'user-notification'
        );
    },
    getInternalUtilityApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalApiPrefix +
            'utility'
        );
    },
    getInternalClearentApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalApiPrefix +
            'clearent-api'
        );
    },
    getInternalCoreForceApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalApiPrefix +
            'coreforce'
        );
    },
    getInternalCoreStoreApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalApiPrefix +
            'corestore'
        );
    },
    getInternalGoogleMapsApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalApiPrefix +
            'google-maps'
        );
    },
    getInternalCoreillaApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalApiPrefix +
            'coreilla'
        );
    },
    getInternalStripeApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl + getters.getInternalApiPrefix + 'stripe'
        );
    },
    isAuthenticated: (state) => {
        return state.isAuthenticated;
    },
    getBusinessById: (state) => (businessId) => {
        return state.businesses.find(
            (business) => business.businessId === businessId
        );
    },
    getBusinessTypeById: (state) => (businessTypeId) => {
        return state.references.businessTypes.find(
            (businessType) => businessType.businessTypeId === businessTypeId
        );
    },
    getBusinessTypeDescriptionById: (state) => (businessTypeId) => {
        let businessType = state.references.businessTypes.find(
            (businessType) => businessType.businessTypeId === businessTypeId
        );

        return businessType ? businessType.description : '';
    },
    getTagDescriptionById: (state) => (tagId) => {
        let tag = state.tags.find((tag) => tag.tagId === tagId);

        return tag ? tag.description : '';
    },
    getTagColorById: (state) => (tagId) => {
        let tag = state.tags.find((tag) => tag.tagId === tagId);

        return tag ? tag.tagColor : '2196f3';
    },
    getCommissionTypeById: (state) => (commissionTypeId) => {
        return state.references.businessTypes.find(
            (commissionType) =>
                commissionType.commissionTypeId === commissionTypeId
        );
    },
    getTouchpointTypeById: (state) => (touchpointTypeId) => {
        return state.references.touchpointTypes.find(
            (touchpointType) =>
                touchpointType.touchpointTypeId === touchpointTypeId
        );
    },
    getProspectSourceTypeById: (state) => (prospectSourceTypeId) => {
        return state.references.prospectSourceTypes.find(
            (prospectSourceType) =>
                prospectSourceType.prospectSourceTypeId === prospectSourceTypeId
        );
    },
    getTouchpointTypeDescriptionById: (state) => (touchpointTypeId) => {
        let touchpointType = state.references.touchpointTypes.find(
            (touchpointType) =>
                touchpointType.touchpointTypeId === touchpointTypeId
        );

        return touchpointType ? touchpointType.description : '';
    },
    getProspectSourceTypeDescriptionById: (state) => (prospectSourceTypeId) => {
        let prospectSourceType = state.references.prospectSourceTypes.find(
            (prospectSourceType) =>
                prospectSourceType.prospectSourceTypeId === prospectSourceTypeId
        );

        return prospectSourceType ? prospectSourceType.description : '';
    },
    getFollowUpTypeDescriptionById: (state) => (followUpTypeId) => {
        let followUpType = state.references.touchpointTypes.find(
            (touchpointType) =>
                touchpointType.touchpointTypeId === followUpTypeId
        );

        return followUpType ? followUpType.description : '';
    },
    getIndustryById: (state) => (industryId) => {
        return state.industries.find(
            (industry) => industry.industryId === industryId
        );
    },
    getIndustryDescriptionById: (state) => (industryId) => {
        let industry = state.industries.find(
            (industry) => industry.industryId === industryId
        );

        return industry ? industry.description : '';
    },
    getHostedZoneById: (state) => (hostedZoneId) => {
        return state.dnsInfo.hostedZones.find(
            (hostedZone) => hostedZone.Id === hostedZoneId
        );
    },
    getPrimaryContactByBusinessId: (state, getters) => (businessId) => {
        let business = getters.getBusinessById(businessId);

        if (business) {
            let businessContact = business.businessContacts.find(
                (businessContact) => businessContact.primaryContact == '1'
            );

            return businessContact ? businessContact : null;
        }

        return null;
    },
    getContactByBusinessIdAndContactId:
        (state, getters) => (businessId, businessContactId) => {
            let business = getters.getBusinessById(businessId);

            if (business) {
                let businessContact = business.businessContacts.find(
                    (businessContact) =>
                        businessContact.businessContactId == businessContactId
                );

                return businessContact ? businessContact : null;
            }

            return null;
        },
    getActiveProductFamilies: (state) => () => {
        return state.productFamilies.filter(
            (productFamily) => productFamily.active == 1
        );
    },
    getProductFamilyById: (state) => (productFamilyId) => {
        return state.productFamilies.find(
            (productFamily) => productFamily.productFamilyId === productFamilyId
        );
    },
    getProductFamilyDescriptionById: (state) => (productFamilyId) => {
        let productFamily = state.productFamilies.find(
            (productFamily) => productFamily.productFamilyId === productFamilyId
        );

        return productFamily ? productFamily.description : '';
    },
    getProductFamilyColorById: (state) => (productFamilyId) => {
        let productFamily = state.productFamilies.find(
            (productFamily) => productFamily.productFamilyId === productFamilyId
        );

        return productFamily ? productFamily.familyColor : '';
    },
    getProductFamilyIconById: (state) => (productFamilyId) => {
        let productFamily = state.productFamilies.find(
            (productFamily) => productFamily.productFamilyId === productFamilyId
        );

        return productFamily ? productFamily.familyIcon : '';
    },
    getProductFamilyByProductId: (state, getters) => (productId) => {
        let product = getters.getProductById(productId);

        return product
            ? getters.getProductFamilyById(product.productFamilyId)
            : null;
    },
    getProductFamilyDescriptionByProductId: (state, getters) => (productId) => {
        let productFamily = getters.getProductFamilyByProductId(productId);

        return productFamily ? productFamily.description : '';
    },
    getProductFamilyColorByProductId: (state, getters) => (productId) => {
        let productFamily = getters.getProductFamilyByProductId(productId);

        return productFamily ? productFamily.familyColor : '';
    },
    getProductFamilyIconByProductId: (state, getters) => (productId) => {
        let productFamily = getters.getProductFamilyByProductId(productId);

        return productFamily ? productFamily.familyIcon : '';
    },
    getActiveProducts: (state, getters) => () => {
        return state.products.filter((product) => {
            let productFamily = getters.getProductFamilyByProductId(
                product.productId
            );
            return productFamily.active == 1 && product.active == 1;
        });
    },
    getProductById: (state) => (productId) => {
        return state.products.find(
            (product) => product.productId === productId
        );
    },
    getProductDescriptionById: (state) => (productId) => {
        let product = state.products.find(
            (product) => product.productId === productId
        );

        return product ? product.description : '';
    },
    getProductActiveStatusById: (state) => (productId) => {
        let product = state.products.find(
            (product) => product.productId === productId
        );

        return product && product.active == 1 ? true : false;
    },
    getUserByUsername: (state) => (Username) => {
        return state.users.find((user) => user.awsUsername === Username);
    },
    getUserByUserId: (state) => (userId) => {
        return state.users.find((user) => user.userId === userId);
    },
    getUserByCoreForceId: (state) => (coreForceId) => {
        return state.users.find((user) => user.coreforceUserId == coreForceId);
    },
    getUserAttribute: () => (user, attributeToFind) => {
        let attribute = user.Attributes.find((a) => a.Name === attributeToFind);

        return attribute ? attribute.Value : '';
    },
    getUserNameByUserId: (state) => (userId) => {
        if (!userId) return null;

        let user = state.users.find((user) => user.userId === userId);

        return user ? user.name : 'Unknown User';
    },
    getSalespersonFromAwsUsername: () => (databaseUsers, awsUsername) => {
        if (!databaseUsers || databaseUsers.length == 0) return null;

        let databaseUser = databaseUsers.find(
            (user) => user.awsUsername == awsUsername
        );

        return databaseUser ? databaseUser.salesperson : '0';
    },
    getUnlistedFromAwsUsername: () => (databaseUsers, awsUsername) => {
        if (!databaseUsers || databaseUsers.length == 0) return null;

        let databaseUser = databaseUsers.find(
            (user) => user.awsUsername == awsUsername
        );

        return databaseUser ? databaseUser.unlisted : '0';
    },
    getCurrentUser: (state) => {
        return state.user;
    },
    getCurrentUserId: (state) => {
        if (state.users.length == 0) return '0';

        let matchedUser = state.users.find(
            (user) => user.awsUsername == state.user.cognitoUser.username
        );

        return matchedUser ? matchedUser.userId : '0';
    },
    getUserIdFromAwsUsername: () => (databaseUsers, awsUsername) => {
        if (!databaseUsers || databaseUsers.length == 0) return null;

        let databaseUser = databaseUsers.find(
            (user) => user.awsUsername == awsUsername
        );

        return databaseUser ? databaseUser.userId : '0';
    },
    getUserPermissionById: (state) => (permissionId) => {
        return state.userPermissions.find(
            (userPermission) =>
                userPermission.permission[0].permissionId == permissionId
        );
    },
    getUserPreferenceValueByKey: (state) => (preferenceKey) => {
        return state.userPreferences[preferenceKey]
            ? state.userPreferences[preferenceKey]
            : null;
    },
    getLastRequestTime: (state) => {
        return state.lastRequestTime;
    },
    getHelpDeskStatusById: (state) => (helpDeskStatusId) => {
        return state.references.coreForce.helpDeskStatuses.find(
            (helpDeskStatus) =>
                helpDeskStatus.help_desk_status_id === helpDeskStatusId
        );
    },
    getHelpDeskStatusDescriptionById: (state) => (helpDeskStatusId) => {
        let helpDeskStatus = state.references.coreForce.helpDeskStatuses.find(
            (helpDeskStatus) =>
                helpDeskStatus.help_desk_status_id === helpDeskStatusId
        );

        return helpDeskStatus ? helpDeskStatus.description : 'N/A';
    },
    getHelpDeskTypeById: (state) => (helpDeskTypeId) => {
        return state.references.coreForce.helpDeskTypes.find(
            (helpDeskType) => helpDeskType.help_desk_type_id === helpDeskTypeId
        );
    },
    getHelpDeskTypeDescriptionById: (state) => (helpDeskTypeId) => {
        let helpDeskType = state.references.coreForce.helpDeskTypes.find(
            (helpDeskType) => helpDeskType.help_desk_type_id === helpDeskTypeId
        );

        return helpDeskType ? helpDeskType.description : 'N/A';
    },
    getHelpDeskCategoryById: (state) => (helpDeskCategoryId) => {
        return state.references.coreForce.helpDeskCategories.find(
            (helpDeskCategory) =>
                helpDeskCategory.help_desk_category_id === helpDeskCategoryId
        );
    },
    getHelpDeskCategoryDescriptionById: (state) => (helpDeskCategoryId) => {
        let helpDeskCategory =
            state.references.coreForce.helpDeskCategories.find(
                (helpDeskCategory) =>
                    helpDeskCategory.help_desk_category_id ===
                    helpDeskCategoryId
            );

        return helpDeskCategory ? helpDeskCategory.description : 'N/A';
    },
    getHelpDeskTagById: (state) => (helpDeskTagId) => {
        return state.references.coreForce.helpDeskTags.find(
            (helpDeskTag) => helpDeskTag.help_desk_tag_id === helpDeskTagId
        );
    },
    getHelpDeskTagDescriptionById: (state) => (helpDeskTagId) => {
        let helpDeskTag = state.references.coreForce.helpDeskTags.find(
            (helpDeskTag) => helpDeskTag.help_desk_tag_id === helpDeskTagId
        );

        return helpDeskTag ? helpDeskTag.description : 'N/A';
    },
};
