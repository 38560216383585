<template>
    <div>
        <v-navigation-drawer v-model="drawer" bottom app>
            <v-list shaped>
                <v-list-item-group
                    v-model="itemGroup"
                    active-class="teal--text"
                >
                    <template v-for="(item, index) in items">
                        <template
                            v-if="
                                !item.permission ||
                                getUserPermissionById(item.permission)
                            "
                        >
                            <template v-if="item.subItems.length > 0">
                                <v-list-group
                                    :key="index"
                                    :prepend-icon="item.icon"
                                    :group="item.route"
                                    no-action
                                >
                                    <template v-slot:activator>
                                        <v-list-item-title>
                                            {{ item.title }}
                                        </v-list-item-title>
                                    </template>
                                    <v-list-item
                                        v-for="(
                                            subItem, subIndex
                                        ) in item.subItems"
                                        :key="subIndex"
                                        :to="'/' + subItem.route"
                                    >
                                        <v-list-item-title>
                                            {{ subItem.title }}
                                        </v-list-item-title>
                                        <v-list-item-action>
                                            <v-icon>
                                                {{ subItem.icon }}
                                            </v-icon>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list-group>
                            </template>
                            <template v-else>
                                <v-list-item
                                    :key="index"
                                    :to="'/' + item.route"
                                    active-class="teal--text"
                                >
                                    <v-list-item-action>
                                        <v-icon>
                                            {{ item.icon }}
                                        </v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ item.title }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </template>
                    </template>
                </v-list-item-group>
            </v-list>
            <template v-slot:append>
                <div class="pa-2">
                    <span class="text-center text--disabled text-caption">
                        {{ gitHash }}
                    </span>
                    <v-btn block outlined color="error" @click="logout">
                        <v-icon left>mdi-logout</v-icon>
                        Logout
                    </v-btn>
                </div>
            </template>
        </v-navigation-drawer>
        <v-app-bar app color="grey darken-4" dark>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
            <v-toolbar-title>{{ appTitle }}</v-toolbar-title>
            <v-spacer />
            <v-menu
                v-if="this.user != null"
                bottom
                min-width="200px"
                rounded
                offset-y
            >
                <template v-slot:activator="{ on }">
                    <v-btn icon x-large v-on="on">
                        <profile-avatar :user="user" />
                    </v-btn>
                </template>
                <v-card>
                    <v-list-item-content class="justify-center">
                        <div class="mx-auto text-center">
                            <profile-avatar :user="user" />
                            <h3>{{ fullName }}</h3>
                            <p class="caption mt-1">
                                {{ user.attributes.email }}
                            </p>
                            <v-divider class="my-3" />
                            <v-btn rounded text to="/profile/view"
                                >View Profile</v-btn
                            >
                        </div>
                    </v-list-item-content>
                </v-card>
            </v-menu>
        </v-app-bar>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ProfileAvatar from '@/components/ProfileAvatar';

export default {
    name: 'AppNavigation',
    components: { ProfileAvatar },
    data() {
        return {
            appTitle: 'coreHUB',
            showInitials: false,
            drawer: null,
            itemGroup: null,
            items: [
                {
                    title: 'Dashboard',
                    icon: 'mdi-view-dashboard',
                    route: '',
                    subItems: [],
                    permission: this.$constants.permissions.dashboard.view,
                },
                {
                    title: 'Businesses',
                    icon: 'mdi-account-multiple',
                    route: 'business',
                    subItems: [
                        {
                            title: 'Business List',
                            icon: 'mdi-list-box-outline',
                            route: 'business/list',
                        },
                        {
                            title: 'coreWHERE',
                            icon: 'mdi-map',
                            route: 'business/map',
                        },
                        {
                            title: 'Prospect Sources',
                            icon: 'mdi-list-box-outline',
                            route: 'business/prospect-sources',
                        },
                    ],
                    permission: this.$constants.permissions.business.view,
                },
                {
                    title: 'Billing',
                    icon: 'mdi-receipt-text',
                    route: 'billing',
                    subItems: [
                        {
                            title: 'Billing List',
                            icon: 'mdi-list-box-outline',
                            route: 'billing/list',
                        },
                    ],
                    permission: this.$constants.permissions.billing.view,
                },
                {
                    title: 'Help Desk',
                    icon: 'mdi-face-agent',
                    route: 'help-desk/list',
                    subItems: [],
                    permission: this.$constants.permissions.helpDesk.view,
                },
                {
                    title: 'Sales',
                    icon: 'mdi-credit-card-outline',
                    route: 'sales',
                    subItems: [
                        {
                            title: 'Sales Stats',
                            icon: 'mdi-chart-line',
                            route: 'sales/stats',
                        },
                    ],
                    permission: this.$constants.permissions.sales.view,
                },
                {
                    title: 'Merchant Dashboard',
                    icon: 'mdi-view-dashboard',
                    route: 'merchant/dashboard',
                    subItems: [],
                    permission: this.$constants.permissions.merchant.view,
                },
                {
                    title: 'Industries',
                    icon: 'mdi-domain',
                    route: 'industry',
                    subItems: [
                        {
                            title: 'Industry List',
                            icon: 'mdi-list-box-outline',
                            route: 'industry/list',
                        },
                    ],
                    permission: this.$constants.permissions.industry.view,
                },
                {
                    title: 'Products',
                    icon: 'mdi-list-box-outline',
                    route: 'product',
                    subItems: [
                        {
                            title: 'Product List',
                            icon: 'mdi-list-box-outline',
                            route: 'product/list',
                        },
                        {
                            title: 'Product Families',
                            icon: 'mdi-list-box-outline',
                            route: 'product-family/list',
                        },
                    ],
                    permission: this.$constants.permissions.product.view,
                },
                {
                    title: 'Tags',
                    icon: 'mdi-tag',
                    route: 'tag',
                    subItems: [
                        {
                            title: 'Tag List',
                            icon: 'mdi-list-box-outline',
                            route: 'tag/list',
                        },
                    ],
                    permission: this.$constants.permissions.tag.view,
                },
                {
                    title: 'Users',
                    icon: 'mdi-account',
                    route: 'user',
                    subItems: [
                        {
                            title: 'User List',
                            icon: 'mdi-list-box-outline',
                            route: 'user/list',
                        },
                    ],
                    permission: this.$constants.permissions.users.view,
                },
                {
                    title: 'Directory',
                    icon: 'mdi-card-account-details',
                    route: 'directory',
                    subItems: [],
                },
            ],
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user.cognitoUser,
            userPermissions: 'userPermissions',
        }),
        ...mapGetters(['getUserPermissionById']),
        fullName() {
            let firstName = this.user.attributes.given_name ?? null;
            let lastName = this.user.attributes.family_name ?? null;

            return firstName || lastName ? firstName + ' ' + lastName : null;
        },
        gitHash() {
            return process.env.VUE_APP_GIT_HASH;
        },
    },
    methods: {
        logout() {
            this.$store.dispatch('userLogout');
        },
    },
};
</script>
