<template>
    <v-snackbar v-model="show" right timeout="-1">
        New app version available!
        <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="updateApp">
                Update
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    name: 'UpdateSnackbar',
    data() {
        return {
            show: false,
            refreshing: false,
            registration: null,
        };
    },
    created() {
        console.log('Adding event listener for service worker');
        document.addEventListener('serviceWorkerUpdated', this.showSnackbar);

        if (navigator.serviceWorker) {
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                console.log('Refreshing...');
                if (this.refreshing) return;
                this.refreshing = true;
                window.location.reload();
            });
        }
    },
    methods: {
        showSnackbar(event) {
            console.log('Showing update snackbar...');

            this.registration = event.detail;
            this.show = true;
        },
        updateApp() {
            console.log('Updating app...');

            this.show = false;

            if (!this.registration || !this.registration.waiting) {
                return;
            }

            this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        },
    },
};
</script>
