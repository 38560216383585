/* eslint-disable no-console */

import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
            console.log(
                'App is being served from cache by a service worker.\n' +
                    'For more details, visit https://goo.gl/AFskqB'
            );
        },
        registered(registration) {
            setInterval(() => {
                registration.update();
                console.log('Updating registration...');
            }, 1000 * 60); // check every minute
        },
        cached() {
            console.log('Content has been cached for offline use.');
        },
        updatefound() {
            console.log('New content is downloading.');
        },
        updated(registration) {
            console.log('New content has been downloaded.');

            document.dispatchEvent(
                new CustomEvent('serviceWorkerUpdated', {
                    detail: registration,
                })
            );
        },
        offline() {
            console.log(
                'No internet connection found. App is running in offline mode.'
            );
        },
        error(error) {
            console.error('Error during service worker registration:', error);
        },
    });
}
