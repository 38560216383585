import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import Constants from '@/plugins/constants';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: () =>
            import(
                /* webpackChunkName: "dashboard" */ '../views/dashboard/DashboardScreen'
            ),
        meta: {
            authRequired: true,
            permission: Constants.permissions.dashboard.view,
        },
    },
    {
        path: '/home',
        name: 'Home',
        component: () =>
            import(
                /* webpackChunkName: "home" */ '../views/dashboard/HomeScreen'
            ),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/login',
        name: 'Login',
        // route level code-splitting
        // this generates a separate chunk (login.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "login" */ '../views/auth/LoginScreen'),
        meta: {
            authRequired: false,
            hideNav: true,
        },
    },
    {
        path: '/first-login',
        name: 'FirstLogin',
        component: () =>
            import(
                /* webpackChunkName: "first-login" */ '../views/auth/FirstLoginScreen'
            ),
        meta: {
            authRequired: false,
            hideNav: true,
        },
    },
    {
        path: '/password-reset',
        name: 'PasswordReset',
        component: () =>
            import(
                /* webpackChunkName: "password-reset" */ '../views/auth/PasswordReset'
            ),
        meta: {
            authRequired: false,
        },
    },
    {
        path: '/product',
        name: 'Product',
        component: () =>
            import(
                /* webpackChunkName: "product" */ '../views/product/ProductScreen'
            ),
        children: [
            {
                path: 'list',
                component: () =>
                    import(
                        /* webpackChunkName: "product" */ '../views/product/ProductList'
                    ),
            },
        ],
        meta: {
            authRequired: true,
            permission: Constants.permissions.product.view,
        },
    },
    {
        path: '/product-family',
        name: 'ProductFamily',
        component: () =>
            import(
                /* webpackChunkName: "product-family" */ '../views/product-family/ProductFamilyScreen'
            ),
        children: [
            {
                path: 'list',
                component: () =>
                    import(
                        /* webpackChunkName: "product-family" */ '../views/product-family/ProductFamilyList'
                    ),
            },
        ],
        meta: {
            authRequired: true,
            permission: Constants.permissions.product.view,
        },
    },
    {
        path: '/industry',
        name: 'Industry',
        component: () =>
            import(
                /* webpackChunkName: "industry" */ '../views/industry/IndustryScreen'
            ),
        children: [
            {
                path: 'list',
                component: () =>
                    import(
                        /* webpackChunkName: "industry" */ '../views/industry/IndustryList'
                    ),
            },
        ],
        meta: {
            authRequired: true,
            permission: Constants.permissions.industry.view,
        },
    },
    {
        path: '/tag',
        name: 'Tag',
        component: () =>
            import(/* webpackChunkName: "tag" */ '../views/tag/TagScreen'),
        children: [
            {
                path: 'list',
                component: () =>
                    import(
                        /* webpackChunkName: "tag" */ '../views/tag/TagList'
                    ),
            },
        ],
        meta: {
            authRequired: true,
            permission: Constants.permissions.tag.view,
        },
    },
    {
        path: '/business',
        name: 'Business',
        component: () =>
            import(
                /* webpackChunkName: "business" */ '../views/business/BusinessScreen'
            ),
        children: [
            {
                path: 'list',
                component: () =>
                    import(
                        /* webpackChunkName: "business" */ '../views/business/BusinessList'
                    ),
            },
            {
                path: 'map',
                component: () =>
                    import(
                        /* webpackChunkName: "business-map" */ '../views/business/BusinessMap'
                    ),
            },
            {
                path: 'view/:businessId',
                name: 'BusinessView',
                component: () =>
                    import(
                        /* webpackChunkName: "business-view" */ '../views/business/BusinessView'
                    ),
                props: true,
            },
            {
                path: 'prospect-sources',
                component: () =>
                    import(
                        /* webpackChunkName: "business-prospect-sources" */ '../views/business/ProspectSources'
                    ),
            },
            {
                path: 'import-spreadsheet',
                component: () =>
                    import(
                        /* webpackChunkName: "business-import" */ '../views/business/ImportSpreadsheet'
                    ),
            },
            {
                path: 'merge',
                component: () =>
                    import(
                        /* webpackChunkName: "business" */ '../views/business/Merge'
                    ),
            },
        ],
        meta: {
            authRequired: true,
            permission: Constants.permissions.business.view,
        },
    },
    {
        path: '/billing',
        name: 'Billing',
        component: () =>
            import(
                /* webpackChunkName: "billing" */ '../views/billing/BillingScreen'
            ),
        children: [
            {
                path: 'list',
                component: () =>
                    import(
                        /* webpackChunkName: "billing" */ '../views/billing/BillingList'
                    ),
            },
        ],
        meta: {
            authRequired: true,
            permission: Constants.permissions.billing.view,
        },
    },
    {
        path: '/business-product-revenue',
        name: 'BusinessProductRevenue',
        component: () =>
            import(
                /* webpackChunkName: "business-product-revenue" */ '../views/business-product-revenue/BusinessProductRevenueScreen'
            ),
        children: [
            {
                path: 'orphaned-merchants',
                component: () =>
                    import(
                        /* webpackChunkName: "orphaned-merchants" */ '../views/business-product-revenue/OrphanedMerchantsList'
                    ),
            },
        ],
        meta: {
            authRequired: true,
            permission: Constants.permissions.revenue.view,
        },
    },
    {
        path: '/user',
        name: 'User',
        component: () =>
            import(/* webpackChunkName: "user" */ '../views/user/UserScreen'),
        children: [
            {
                path: 'list',
                component: () =>
                    import(
                        /* webpackChunkName: "user" */ '../views/user/UserList'
                    ),
            },
            {
                path: 'view/:username',
                component: () =>
                    import(
                        /* webpackChunkName: "user-view" */ '../views/user/UserView'
                    ),
                props: true,
            },
        ],
        meta: {
            authRequired: true,
            permission: Constants.permissions.users.view,
        },
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () =>
            import(
                /* webpackChunkName: "profile" */ '../views/profile/ProfileScreen'
            ),
        children: [
            {
                path: 'view',
                component: () =>
                    import(
                        /* webpackChunkName: "profile" */ '../views/profile/ProfileView'
                    ),
            },
        ],
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/merchant',
        name: 'Merchant',
        component: () =>
            import(
                /* webpackChunkName: "merchant" */ '../views/merchant/MerchantScreen'
            ),
        children: [
            {
                path: 'dashboard',
                component: () =>
                    import(
                        /* webpackChunkName: "merchant" */ '../views/merchant/MerchantDashboard'
                    ),
            },
            {
                path: 'application/:merchantNumber',
                component: () =>
                    import(
                        /* webpackChunkName: "merchant-application" */ '../views/merchant/MerchantApplication'
                    ),
                props: true,
            },
        ],
        meta: {
            authRequired: true,
            permission: Constants.permissions.merchant.view,
        },
    },
    {
        path: '/merchant-form',
        name: 'MerchantForm',
        component: () =>
            import(
                /* webpackChunkName: "merchant-form" */ '../views/merchant/MerchantForm'
            ),
        meta: {
            authRequired: false,
            hideNav: true,
        },
    },
    {
        path: '/merchant-signatures/:merchantId/:contactId',
        name: 'MerchantSignatures',
        component: () =>
            import(
                /* webpackChunkName: "merchant-signatures" */ '../views/merchant/MerchantSignatures'
            ),
        props: true,
        meta: {
            authRequired: false,
            hideNav: true,
        },
    },
    {
        path: '/merchant-terms/print/:merchantId/:contactId/:termsId',
        name: 'MerchantTerms',
        component: () =>
            import(
                /* webpackChunkName: "merchant-terms" */ '../views/merchant/DisclosurePrintView'
            ),
        props: true,
        meta: {
            authRequired: false,
            hideNav: true,
        },
    },
    {
        path: '/sales',
        name: 'Sales',
        component: () =>
            import(
                /* webpackChunkName: "sales" */ '../views/sales/SalesScreen'
            ),
        children: [
            {
                path: 'stats',
                name: 'SalesStats',
                component: () =>
                    import(
                        /* webpackChunkName: "sales" */ '../views/sales/SalesStats'
                    ),
            },
        ],
        meta: {
            authRequired: true,
            permission: Constants.permissions.sales.view,
        },
    },
    {
        path: '/directory',
        name: 'Directory',
        component: () =>
            import(
                /* webpackChunkName: "user-directory" */ '../views/user/UserDirectory'
            ),
        meta: {
            authRequired: false,
        },
    },
    {
        path: '/help-desk',
        name: 'HelpDesk',
        component: () =>
            import(
                /* webpackChunkName: "help-desk" */ '../views/help-desk/HelpDeskScreen'
            ),
        children: [
            {
                path: 'list',
                component: () =>
                    import(
                        /* webpackChunkName: "help-desk" */ '../views/help-desk/HelpDeskList'
                    ),
            },
            {
                path: 'view/:helpDeskTicketId',
                name: 'HelpDeskView',
                component: () =>
                    import(
                        /* webpackChunkName: "help-desk" */ '../views/help-desk/HelpDeskView'
                    ),
                props: true,
            },
        ],
        meta: {
            authRequired: true,
            permission: Constants.permissions.helpDesk.view,
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    store.state.hideNav = to.matched.some((record) => record.meta.hideNav);

    // Applies to any route with authRequired defined as true
    // TODO: Change to require auth by default, unless set otherwise
    if (to.matched.some((record) => record.meta.authRequired)) {
        if (!store.getters.isAuthenticated) {
            next({
                path: '/login',
                query: { redirect: to.fullPath },
            });
        } else {
            store.dispatch('userPermissionRead').then(() => {
                if (to.matched.find((record) => record.meta.permission)) {
                    let parent = to.matched.find(
                        (record) => record.meta.permission
                    );
                    if (
                        !store.getters.getUserPermissionById(
                            parent.meta.permission
                        )
                    ) {
                        next({
                            path: '/home',
                        });
                    } else {
                        next();
                    }
                } else {
                    next();
                }
            });
        }
    } else {
        next();
    }
});

export default router;
