import Vue from 'vue';

Vue.filter('website', function (value) {
    if (!value) return '';
    return value.replace(/(^\w+:|^)\/\//, '');
});

Vue.filter('phone', function (value) {
    if (!value) return '';
    return value
        .replace(/[^0-9]/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
});
