import Vue from 'vue';
import Vuex from 'vuex';
import actions from '@/store/actions';
import getters from '@/store/getters';
import mutations from '@/store/mutations';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        snackbarMessage: '',
        user: {
            cognitoUser: {},
            databaseUser: {},
            cognitoUserGroup: {},
        },
        userPermissions: [],
        userPreferences: {},
        isAuthenticated: false,
        hideNav: false,
        references: {
            businessTypes: [],
            commissionTypes: [],
            touchpointTypes: [],
            prospectSourceTypes: [],
            coreForce: [],
        },
        persist: {},
        cognitoAuthState: {},
        productFamilies: [],
        products: [],
        businesses: [],
        industries: [],
        prospectSources: [],
        tags: [],
        users: [],
        orphanedMerchants: [],
        dnsInfo: {
            hostedZones: {},
            regions: {},
            loadBalancers: {},
        },
        sendGridInfo: {
            lists: [],
        },
        lastRequestTime: {
            businesses: 0,
            products: 0,
            businessProducts: 0,
            industries: 0,
            prospectSources: 0,
        },
    },
    mutations,
    actions,
    getters,
});
