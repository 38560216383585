import router from '@/router';
import axios from 'axios';
import Auth from '@aws-amplify/auth';
import CognitoIdentityServiceProvider from 'aws-sdk/clients/cognitoidentityserviceprovider';

export default {
    async initialize({ dispatch }) {
        // Functions on app load
        await dispatch('userRead');
        await dispatch('databaseUserReadCurrent');
        await dispatch('userPermissionRead');
        await dispatch('userPreferenceRead');
        dispatch('referencesRead');
        dispatch('coreForceReferencesRead');
        dispatch('productFamilyRead');
        dispatch('businessRead');
        dispatch('productRead');
        dispatch('industryRead');
        dispatch('prospectSourceRead');
        dispatch('tagRead');
        dispatch('dnsInfoRead');
        dispatch('sendGridInfoRead');
    },
    userLogout({ commit }) {
        Auth.signOut();
        commit('setCognitoUser', null);
        commit('setIsAuthenticated', false);
        router.push('/login');
    },
    async referencesRead({ getters, commit }) {
        try {
            let response = await axios.get(
                `${getters.getInternalReferenceApi + '/read'}`,
                {
                    params: {},
                }
            );

            if (response.data) {
                commit('setBusinessTypes', response.data['businessTypes']);
                commit('setCommissionTypes', response.data['commissionTypes']);
                commit('setTouchpointTypes', response.data['touchpointTypes']);
                commit(
                    'setProspectSourceTypes',
                    response.data['prospectSourceTypes']
                );
            } else {
                commit('setSnackbarMessage', 'No reference data found.');
                commit('setBusinessTypes', []);
                commit('setCommissionTypes', []);
                commit('setTouchpointTypes', []);
                commit('setProspectSourceTypes', []);
            }
        } catch (error) {
            commit(
                'setSnackbarMessage',
                "Couldn't load references. Error: " + error.message
            );
            commit('setBusinessTypes', []);
            commit('setCommissionTypes', []);
            commit('setTouchpointTypes', []);
            commit('setProspectSourceTypes', []);
        }
    },
    async coreForceReferencesRead({ getters, commit }) {
        try {
            let response = await axios.get(
                `${getters.getInternalCoreForceApi + '/get-references'}`,
                {
                    params: {},
                }
            );

            if (response.data.success === true) {
                commit('setCoreForceReferences', response.data.data);
            } else {
                commit(
                    'setSnackbarMessage',
                    'No coreFORCE reference data found.'
                );
                commit('setCoreForceReferences', []);
            }
        } catch (error) {
            commit(
                'setSnackbarMessage',
                "Couldn't load coreFORCE references. Error: " + error.message
            );
            commit('setCoreForceReferences', []);
        }
    },
    async dnsInfoRead({ getters, commit }) {
        try {
            let response = await axios.get(
                `${getters.getInternalDnsInfoApi + '/read'}`,
                {
                    params: {},
                }
            );
            commit('setDnsInfo', response.data);
        } catch (error) {
            commit('setDnsInfo', {});
        }
    },
    async sendGridInfoRead({ getters, commit }) {
        try {
            let response = await axios.get(
                `${getters.getInternalSendgridInfoApi + '/read'}`,
                {
                    params: {},
                }
            );
            commit('setSendGridInfo', response.data);
        } catch (error) {
            commit('setSendGridInfo', {});
        }
    },
    async userCreate(
        { dispatch },
        { username, salesperson, unlisted, reseller }
    ) {
        let credentials = await Auth.currentCredentials();
        let cognito = new CognitoIdentityServiceProvider({
            apiVersion: '2016-04-18',
            region: 'us-east-2',
            credentials: Auth.essentialCredentials(credentials),
        });

        let params = {
            UserPoolId: 'us-east-2_B025H5rmW',
            Username: username,
        };

        let response = cognito.adminCreateUser(params).promise();

        response
            .then((result) => {
                if (result['User']) {
                    console.log('AWS user created!');

                    let formData = new FormData();
                    formData.append('awsUsername', result['User'].Username);
                    formData.append('email', username);
                    formData.append('salesperson', salesperson);
                    formData.append('unlisted', unlisted);
                    formData.append('reseller', reseller);

                    return dispatch('databaseUserCreate', formData);
                } else {
                    console.error(
                        'AWS Create Error: Could not create user in AWS'
                    );
                    return false;
                }
            })
            .then((result) => {
                if (result) {
                    console.log('User created!');
                } else {
                    console.log('Error creating user');
                }
            })
            .finally(() => dispatch('userRead'));

        return response;
    },
    async userRead({ getters, dispatch, commit }) {
        let databaseUsers = await dispatch('databaseUserRead');
        let awsUsers = await dispatch('awsUserRead');

        if (databaseUsers == null || awsUsers == null) return;

        let users = databaseUsers.map((user) => {
            let awsUser = awsUsers.find(
                (awsUser) => awsUser.Username === user.awsUsername
            );

            if (awsUser) {
                user.avatar = getters.getUserAttribute(awsUser, 'picture');
                user.status = awsUser.UserStatus;
            } else {
                user.avatar = '';
                user.status = '';
            }

            if (user.firstName && user.lastName) {
                user.name = user.firstName + ' ' + user.lastName;
            } else {
                user.name = user.email;
            }

            return user;
        });

        commit('setUsers', users);
    },
    async userUpdate({ commit }, { formData }) {
        let credentials = await Auth.currentCredentials();
        let cognito = new CognitoIdentityServiceProvider({
            apiVersion: '2016-04-18',
            region: 'us-east-2',
            credentials: Auth.essentialCredentials(credentials),
        });
        let accessToken = (await Auth.currentSession())
            .getAccessToken()
            .getJwtToken();

        let params = {
            AccessToken: accessToken,
            UserAttributes: formData,
        };

        cognito.updateUserAttributes(params, (error, data) => {
            if (error) {
                console.log('Error: ' + error + ' Details: ' + error.stack);
            } else {
                console.log('User Updated: ' + data.toString());
                commit('setUserAttributes', formData);
            }
        });
    },
    async userDelete({ dispatch, commit }, { username, userId }) {
        let credentials = await Auth.currentCredentials();
        let cognito = new CognitoIdentityServiceProvider({
            apiVersion: '2016-04-18',
            region: 'us-east-2',
            credentials: Auth.essentialCredentials(credentials),
        });

        let params = {
            UserPoolId: 'us-east-2_B025H5rmW',
            Username: username,
        };

        let response = cognito.adminDeleteUser(params).promise();

        response
            .then(() => {
                dispatch('databaseUserDelete', userId);
                commit('removeUser', username);
            })
            .catch((error) => {
                console.log("Couldn't delete user. Error: " + error);
            })
            .finally(() => dispatch('userRead'));

        return response;
    },
    async userGroupRead({ commit }) {
        let credentials = await Auth.currentCredentials();
        let cognito = new CognitoIdentityServiceProvider({
            apiVersion: '2016-04-18',
            region: 'us-east-2',
            credentials: Auth.essentialCredentials(credentials),
        });
        let currentUserInfo = await Auth.currentUserInfo();

        let params = {
            UserPoolId: 'us-east-2_B025H5rmW',
            Username: currentUserInfo.username,
        };

        cognito.adminListGroupsForUser(params, (error, data) => {
            if (error) {
                console.log('Error: ' + error + ' Details: ' + error.stack);
            } else {
                commit('setCognitoUserGroup', data['Groups']);
            }
        });
    },
    async setUserPassword({ state }, { username, newPassword }) {
        let credentials = await Auth.currentCredentials();
        let cognito = new CognitoIdentityServiceProvider({
            apiVersion: '2016-04-18',
            region: 'us-east-2',
            credentials: Auth.essentialCredentials(credentials),
        });

        let params = {
            UserPoolId: 'us-east-2_B025H5rmW',
            Username: username,
            Password: newPassword,
            Permanent: true,
        };

        console.log(
            'Attempting to set password for ' + username + ' to ' + newPassword
        );

        console.log(state.user.cognitoUser.user);

        cognito.adminSetUserPassword(params, (error, data) => {
            if (error) {
                console.log('Error: ' + error + ' Details: ' + error.stack);
            } else {
                console.log(data);
            }
        });
    },
    async currentUserRead({ commit }) {
        let cognitoUser = await Auth.currentAuthenticatedUser({
            bypassCache: true,
        });
        commit('setCognitoUser', cognitoUser);
    },
    async databaseUserCreate({ getters }, formData) {
        try {
            let response = await axios.post(
                `${getters.getInternalUserApi + '/create'}`,
                formData,
                null
            );

            if (response.data['status'] && response.data['status'] == 'error') {
                console.error('Could not add user to database');
                return false;
            } else {
                console.log('User added to database!');
                console.log(response.data['response']);
                return true;
            }
        } catch (error) {
            console.error('Could not add user to database');
            console.error(error);
            return false;
        }
    },
    async databaseUserRead({ getters }) {
        try {
            let response = await axios.get(
                `${getters.getInternalUserApi + '/read'}`
            );

            if (response.data.length == 0) {
                console.error('Could not get database users');
                console.error(response.data);
                return null;
            } else {
                return response.data;
            }
        } catch (error) {
            console.error('Could not get database users');
            console.error(error);
            return null;
        }
    },
    async databaseUserUpdate({ getters }, formData) {
        formData.userId = getters.getCurrentUserId;

        try {
            let response = await axios.post(
                `${getters.getInternalUserApi + '/update'}`,
                formData
            );

            if (response.data.success === true) {
                console.log(response.data.data);
                return response.data.data;
            } else {
                console.error('Could not update database user');
                console.error(response.data);
                return null;
            }
        } catch (error) {
            console.error('Could not update database user');
            console.error(error);
        }
    },
    async databaseUserDelete({ getters }, userId) {
        let formData = new FormData();
        formData.append('userId', userId);

        try {
            let response = await axios.post(
                `${getters.getInternalUserApi + '/delete'}`,
                formData,
                null
            );

            if (response.data['status']['error']) {
                console.error('Could not delete user from database');
                return false;
            } else {
                console.log('User deleted from database!');
                console.log(response.data['response']);
                return true;
            }
        } catch (error) {
            console.error('Could not remove user from database');
            console.error(error);
            return false;
        }
    },
    async databaseUserReadCurrent({ getters, commit }) {
        try {
            let response = await axios.get(
                `${getters.getInternalUserApi + '/read'}`,
                {
                    params: {
                        userId: getters.getCurrentUserId,
                    },
                }
            );

            if (response.data.length == 0) {
                console.error('Could not get current database user');
                console.error(response.data);
            } else {
                commit('setDatabaseUser', response.data[0]);
            }
        } catch (error) {
            console.error('Could not get current database user');
            console.error(error);
        }
    },
    async awsUserRead() {
        let credentials = await Auth.currentCredentials();
        let cognito = new CognitoIdentityServiceProvider({
            apiVersion: '2016-04-18',
            region: 'us-east-2',
            credentials: Auth.essentialCredentials(credentials),
        });

        let response = [];
        let paginationToken = null;

        do {
            let params = {
                UserPoolId: 'us-east-2_B025H5rmW',
                PaginationToken: paginationToken,
            };

            let data = await cognito.listUsers(params).promise();
            response = response.concat(data.Users);
            paginationToken = data.PaginationToken;
        } while (paginationToken);

        return response;
    },
    async productCreate({ getters }, formData) {
        try {
            let response = await axios.post(
                `${getters.getInternalProductApi + '/create'}`,
                formData
            );

            if (response.data['error'] == null) {
                return response.data;
            } else {
                return response.data['error'];
            }
        } catch (error) {
            console.error('Could not create product');
            console.error(error);
        }
    },
    async productRead({ getters, commit }) {
        const timeNow = Date.now();
        const lastRequestTime = getters.getLastRequestTime.products;

        if (lastRequestTime != 0 && timeNow - lastRequestTime < 20000) {
            return;
        }

        commit('setLastRequestTime', {
            name: 'products',
            value: Date.now(),
        });

        try {
            let response = await axios.get(
                `${getters.getInternalProductApi + '/read'}`,
                {
                    params: {},
                }
            );
            commit('setProducts', response.data);
        } catch (error) {
            commit('setProducts', {});
        }
    },
    async productUpdate({ getters }, formData) {
        try {
            let response = await axios.post(
                `${getters.getInternalProductApi + '/update'}`,
                formData
            );

            if (response.data['error'] == null) {
                return response.data;
            } else {
                return response.data['error'];
            }
        } catch (error) {
            console.error('Could not update product');
            console.error(error);
        }
    },
    async productDelete({ getters }, productId) {
        try {
            let response = await axios.post(
                `${getters.getInternalProductApi + '/delete'}`,
                productId
            );

            if (response.data['error'] == null) {
                return true;
            } else {
                return response.data['error'];
            }
        } catch (error) {
            console.error('Could not delete product');
            console.error(error);
        }
    },
    async productFamilyCreate({ getters }, formData) {
        try {
            let response = await axios.post(
                `${getters.getInternalProductFamilyApi + '/create'}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.data['error'] == null) {
                return response.data;
            } else {
                return response.data['error'];
            }
        } catch (error) {
            console.error('Could not create product family');
            console.error(error);
        }
    },
    async productFamilyRead({ getters, commit }) {
        try {
            let response = await axios.get(
                `${getters.getInternalProductFamilyApi + '/read'}`,
                {
                    params: {},
                }
            );
            commit('setProductFamilies', response.data);
        } catch (error) {
            commit('setProductFamilies', {});
        }
    },
    async productFamilyUpdate({ getters }, formData) {
        try {
            let response = await axios.post(
                `${getters.getInternalProductFamilyApi + '/update'}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.data['error'] == null) {
                return response.data;
            } else {
                return response.data['error'];
            }
        } catch (error) {
            console.error('Could not update product family');
            console.error(error);
        }
    },
    async productFamilyDelete({ getters }, productFamilyId) {
        try {
            let response = await axios.post(
                `${getters.getInternalProductFamilyApi + '/delete'}`,
                productFamilyId
            );

            if (response.data['error'] == null) {
                return true;
            } else {
                return response.data['error'];
            }
        } catch (error) {
            console.error('Could not delete product family');
            console.error(error);
        }
    },
    async industryCreate({ getters }, formData) {
        try {
            let response = await axios.post(
                `${getters.getInternalIndustryApi + '/create'}`,
                formData
            );

            if (response.data.success === true) {
                return response.data.data;
            } else {
                return response.data.errors;
            }
        } catch (error) {
            console.error('Could not create industry');
            console.error(error);
        }
    },
    async industryRead({ getters, commit }) {
        const timeNow = Date.now();
        const lastRequestTime = getters.getLastRequestTime.industries;

        if (lastRequestTime != 0 && timeNow - lastRequestTime < 1000) {
            return;
        }

        commit('setLastRequestTime', { name: 'industries', value: Date.now() });

        try {
            let response = await axios.get(
                `${getters.getInternalIndustryApi + '/read'}`,
                null
            );
            commit('setIndustries', response.data.data);
        } catch (error) {
            commit('setIndustries', []);
        }
    },
    async industryUpdate({ getters }, formData) {
        try {
            let response = await axios.post(
                `${getters.getInternalIndustryApi + '/update'}`,
                formData
            );

            if (response.data.success === true) {
                return response.data.data;
            } else {
                return response.data.errors;
            }
        } catch (error) {
            console.error('Could not update industry');
            console.error(error);
        }
    },
    async industryDelete({ getters }, industryId) {
        try {
            let response = await axios.post(
                `${getters.getInternalIndustryApi + '/delete'}`,
                industryId
            );

            if (response.data.success === true) {
                return true;
            } else {
                return response.data.errors;
            }
        } catch (error) {
            console.error('Could not delete industry');
            console.error(error);
        }
    },
    async tagCreate({ getters }, formData) {
        try {
            let response = await axios.post(
                `${getters.getInternalTagApi + '/create'}`,
                formData
            );

            if (response.data.success === true) {
                return response.data.data;
            } else {
                return response.data.errors;
            }
        } catch (error) {
            console.error('Could not create tag');
            console.error(error);
        }
    },
    async tagRead({ getters, commit }) {
        try {
            let response = await axios.get(
                `${getters.getInternalTagApi + '/read'}`,
                null
            );
            commit('setTags', response.data.data);
        } catch (error) {
            commit('setTags', []);
        }
    },
    async tagUpdate({ getters }, formData) {
        try {
            let response = await axios.post(
                `${getters.getInternalTagApi + '/update'}`,
                formData
            );

            if (response.data.success === true) {
                return response.data.data;
            } else {
                return response.data.errors;
            }
        } catch (error) {
            console.error('Could not update tag');
            console.error(error);
        }
    },
    async tagDelete({ getters }, tagId) {
        try {
            let response = await axios.post(
                `${getters.getInternalTagApi + '/delete'}`,
                tagId
            );

            if (response.data.success === true) {
                return true;
            } else {
                return response.data.errors;
            }
        } catch (error) {
            console.error('Could not delete tag');
            console.error(error);
        }
    },
    async businessTagCreate({ getters }, formData) {
        try {
            let response = await axios.post(
                `${getters.getInternalBusinessTagApi + '/create'}`,
                formData
            );

            if (response.data.success === true) {
                return response.data.data;
            } else {
                return response.data.errors;
            }
        } catch (error) {
            console.error('Could not create business tag');
            console.error(error);
        }
    },
    async businessTagRead({ getters, commit }, businessId) {
        try {
            let response = await axios.get(
                `${getters.getInternalBusinessTagApi + '/read'}`,
                {
                    params: {
                        businessId: businessId,
                    },
                }
            );
            return response.data.data;
        } catch (error) {
            commit(
                'setSnackbarMessage',
                "Couldn't load business tags. Error: " + error.message
            );
            return [];
        }
    },
    async businessTagUpdate({ getters }, formData) {
        try {
            let response = await axios.post(
                `${getters.getInternalBusinessTagApi + '/update'}`,
                formData
            );

            if (response.data.success === true) {
                return response.data.data;
            } else {
                return response.data.errors;
            }
        } catch (error) {
            console.error('Could not update business tag');
            console.error(error);
        }
    },
    async businessTagDelete({ getters }, businessTagId) {
        try {
            let response = await axios.post(
                `${getters.getInternalBusinessTagApi + '/delete'}`,
                businessTagId
            );

            if (response.data.success === true) {
                return true;
            } else {
                return response.data.errors;
            }
        } catch (error) {
            console.error('Could not delete business tag');
            console.error(error);
        }
    },
    async businessCreate({ getters }, formData) {
        try {
            let response = await axios.post(
                `${getters.getInternalBusinessApi + '/create'}`,
                formData
            );

            if (response.data.success === true) {
                return response.data.data;
            } else {
                return response.data.errors;
            }
        } catch (error) {
            console.error('Could not create business');
            console.error(error);
        }
    },
    async businessRead({ getters, commit }) {
        const timeNow = Date.now();
        const lastRequestTime = getters.getLastRequestTime.businesses;
        const currentUser = getters.getCurrentUser;
        let resellerId = null;

        if (
            currentUser.databaseUser.reseller == true &&
            currentUser.databaseUser.salesperson == false
        ) {
            resellerId = currentUser.databaseUser.userId;
        }

        if (lastRequestTime != 0 && timeNow - lastRequestTime < 10000) {
            return;
        }

        commit('setLastRequestTime', { name: 'businesses', value: Date.now() });

        try {
            let response = await axios.get(
                `${getters.getInternalBusinessApi + '/read'}`,
                {
                    params: {
                        resellerId: resellerId,
                    },
                }
            );

            if (response.data.data.length > 0) {
                commit('setBusinesses', response.data.data);
            } else {
                commit('setSnackbarMessage', 'No business data found.');
                commit('setBusinesses', []);
            }
        } catch (error) {
            commit(
                'setSnackbarMessage',
                "Couldn't load businesses. Error: " + error.message
            );
            commit('setBusinesses', []);
        }
    },
    async businessUpdate({ getters }, formData) {
        try {
            let response = await axios.post(
                `${getters.getInternalBusinessApi + '/update'}`,
                formData
            );

            if (response.data.success === true) {
                return response.data.data;
            } else {
                return response.data.errors;
            }
        } catch (error) {
            console.error('Could not update business');
            console.error(error);
        }
    },
    async businessDelete({ getters }, businessId) {
        try {
            let response = await axios.post(
                `${getters.getInternalBusinessApi + '/delete'}`,
                businessId
            );

            if (response.data.success === true) {
                return true;
            } else {
                return response.data.errors;
            }
        } catch (error) {
            console.error('Could not delete business');
            console.error(error);
        }
    },
    async businessProductCreate({ getters }, formData) {
        try {
            let response = await axios.post(
                `${getters.getInternalBusinessProductApi + '/create'}`,
                formData
            );

            return response.data;
        } catch (error) {
            console.error('Could not create business product');
            console.error(error);
        }
    },
    async businessProductRead({ getters, commit }, businessId) {
        const timeNow = Date.now();
        const lastRequestTime = getters.getLastRequestTime.businessProducts;

        if (lastRequestTime != 0 && timeNow - lastRequestTime < 3000) {
            console.log('Too soon to request again');
            return;
        }

        commit('setLastRequestTime', {
            name: 'businessProducts',
            value: Date.now(),
        });

        try {
            let response = await axios.get(
                `${getters.getInternalBusinessProductApi + '/read'}`,
                {
                    params: {
                        businessId: businessId,
                    },
                }
            );
            return response.data.data;
        } catch (error) {
            commit(
                'setSnackbarMessage',
                "Couldn't load business products. Error: " + error.message
            );
            return [];
        }
    },
    async businessProductUpdate({ getters }, formData) {
        try {
            let response = await axios.post(
                `${getters.getInternalBusinessProductApi + '/update'}`,
                formData
            );

            return response.data;
        } catch (error) {
            console.error('Could not update business product');
            console.error(error);
        }
    },
    async businessProductDelete({ getters }, businessProductId) {
        try {
            let response = await axios.post(
                `${getters.getInternalBusinessProductApi + '/delete'}`,
                businessProductId
            );

            if (response.data.success === true) {
                return true;
            } else {
                return response.data.errors;
            }
        } catch (error) {
            console.error('Could not delete business product');
            console.error(error);
        }
    },
    async businessContactCreate({ getters }, formData) {
        try {
            let response = await axios.post(
                `${getters.getInternalBusinessContactApi + '/create'}`,
                formData
            );

            return response.data;
        } catch (error) {
            console.error('Could not create business contact');
            console.error(error);
        }
    },
    async businessContactRead({ getters, commit }, businessId) {
        try {
            let response = await axios.get(
                `${getters.getInternalBusinessContactApi + '/read'}`,
                {
                    params: {
                        businessId: businessId,
                    },
                }
            );
            return response.data.data;
        } catch (error) {
            commit(
                'setSnackbarMessage',
                "Couldn't load business contacts. Error: " + error.message
            );
            return [];
        }
    },
    async businessContactUpdate({ getters }, formData) {
        try {
            let response = await axios.post(
                `${getters.getInternalBusinessContactApi + '/update'}`,
                formData
            );

            if (response.data.success === true) {
                return response.data.data;
            } else {
                return response.data.errors;
            }
        } catch (error) {
            console.error('Could not update business contact');
            console.error(error);
        }
    },
    async businessContactDelete({ getters }, businessContactId) {
        try {
            let response = await axios.post(
                `${getters.getInternalBusinessContactApi + '/delete'}`,
                businessContactId
            );

            if (response.data.success === true) {
                return true;
            } else {
                return response.data.errors;
            }
        } catch (error) {
            console.error('Could not delete business contact');
            console.error(error);
        }
    },
    async userPermissionRead({ getters, commit }) {
        let currentUserInfo = await Auth.currentUserInfo();
        let username = currentUserInfo.username;

        try {
            let response = await axios.get(
                `${getters.getInternalUserPermissionApi + '/read'}`,
                {
                    params: {
                        username: username,
                    },
                }
            );
            if (response.data && response.data.length > 0) {
                commit('setUserPermissions', response.data);
            } else {
                console.log(
                    'Empty response from user permissions for user: ' + username
                );
                commit('setUserPermissions', []);
            }
        } catch (error) {
            console.log('Error getting user permissions for user ' + username);
            commit('setUserPermissions', []);
        }
    },
    async userPreferenceRead({ getters, commit }) {
        let userId = getters.getCurrentUser.databaseUser.userId;

        try {
            let response = await axios.get(
                `${getters.getInternalUserPreferenceApi + '/read'}`,
                {
                    params: {
                        userId: userId,
                    },
                }
            );
            if (response.data && response.data.length > 0) {
                commit('setUserPreferences', response.data);
            } else {
                console.log(
                    'Empty response from user preferences for user: ' + userId
                );
                commit('setUserPreferences', []);
            }
        } catch (error) {
            console.log('Error getting user preferences for user ' + userId);
            commit('setUserPreferences', []);
        }
    },
    async prospectSourceCreate({ getters }, formData) {
        try {
            let response = await axios.post(
                `${getters.getInternalProspectSourceApi + '/create'}`,
                formData
            );

            if (response.data.success === true) {
                return response.data.data;
            } else {
                return response.data.errors;
            }
        } catch (error) {
            console.error('Could not create prospect source');
            console.error(error);
        }
    },
    async prospectSourceRead({ getters, commit }) {
        const timeNow = Date.now();
        const lastRequestTime = getters.getLastRequestTime.prospectSources;

        if (lastRequestTime != 0 && timeNow - lastRequestTime < 1000) {
            return;
        }

        commit('setLastRequestTime', {
            name: 'prospectSources',
            value: Date.now(),
        });

        try {
            let response = await axios.get(
                `${getters.getInternalProspectSourceApi + '/read'}`,
                null
            );
            commit('setProspectSources', response.data.data);
        } catch (error) {
            commit('setProspectSources', []);
        }
    },
    async prospectSourceUpdate({ getters }, formData) {
        try {
            let response = await axios.post(
                `${getters.getInternalProspectSourceApi + '/update'}`,
                formData
            );

            if (response.data.success === true) {
                return response.data.data;
            } else {
                return response.data.errors;
            }
        } catch (error) {
            console.error('Could not update prospect source');
            console.error(error);
        }
    },
    async prospectSourceDelete({ getters }, prospectSourceTypeId) {
        try {
            let response = await axios.post(
                `${getters.getInternalProspectSourceApi + '/delete'}`,
                prospectSourceTypeId
            );

            if (response.data.success === true) {
                return true;
            } else {
                return response.data.errors;
            }
        } catch (error) {
            console.error('Could not delete prospect source');
            console.error(error);
        }
    },
};
