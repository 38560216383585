let Constants = {
    permissions: {
        dashboard: {
            view: 1,
        },
        financials: {
            view: 2,
        },
        users: {
            view: 3,
            edit: 4,
        },
        industry: {
            view: 5,
            edit: 8,
        },
        business: {
            view: 6,
            edit: 9,
            delete: 21,
            import: 19,
            merge: 20,
        },
        product: {
            view: 7,
            edit: 10,
        },
        merchant: {
            view: 11,
        },
        revenue: {
            view: 12,
            edit: 13,
        },
        sales: {
            view: 14,
        },
        tag: {
            view: 15,
            edit: 16,
        },
        helpDesk: {
            view: 17,
            edit: 18,
        },
        billing: {
            view: 22,
            edit: 23,
        },
    },

    install(Vue) {
        Vue.prototype.$constants = {
            permissions: this.permissions,
        };
    },
};

export default Constants;
