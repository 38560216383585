import Vue from 'vue';

export default {
    setCognitoUser(state, payload) {
        state.user.cognitoUser = payload;
    },
    setDatabaseUser(state, payload) {
        state.user.databaseUser = payload;
    },
    setCognitoUserGroup(state, payload) {
        state.user.cognitoUserGroup = payload;
    },
    setIsAuthenticated(state, payload) {
        state.isAuthenticated = payload;
    },
    setBusinessTypes(state, payload) {
        state.references.businessTypes = payload;
    },
    setCommissionTypes(state, payload) {
        state.references.commissionTypes = payload;
    },
    setTouchpointTypes(state, payload) {
        state.references.touchpointTypes = payload;
    },
    setProspectSourceTypes(state, payload) {
        state.references.prospectSourceTypes = payload;
    },
    setCoreForceReferences(state, payload) {
        state.references.coreForce = payload;
    },
    setDnsInfo(state, payload) {
        state.dnsInfo = payload;
    },
    setSendGridInfo(state, payload) {
        state.sendGridInfo = payload;
    },
    setUsers(state, payload) {
        state.users = payload;
    },
    updateUser(state, payload) {
        let userIndex = state.users.findIndex(
            (user) => user.userId === payload.userId
        );

        if (userIndex > -1) {
            Vue.set(state.users, userIndex, payload);
        } else {
            console.log('Update User Error: User index not found.');
        }
    },
    removeUser(state, payload) {
        const i = state.users.map((user) => user.username).indexOf(payload);
        state.users.splice(i, 1);
    },
    setProductFamilies(state, payload) {
        state.productFamilies = payload;
    },
    updateProductFamily(state, payload) {
        let productFamilyIndex = state.productFamilies.findIndex(
            (productFamily) =>
                productFamily.productFamilyId === payload.productFamilyId
        );

        if (productFamilyIndex > -1) {
            Vue.set(state.productFamilies, productFamilyIndex, payload);
        } else {
            console.log(
                'Update Product Family Error: Product Family index not found.'
            );
        }
    },
    setProducts(state, payload) {
        state.products = payload;
    },
    createProduct(state, payload) {
        state.products = [...state.products, payload];
    },
    updateProduct(state, payload) {
        let productIndex = state.products.findIndex(
            (product) => product.productId === payload.productId
        );

        if (productIndex > -1) {
            Vue.set(state.products, productIndex, payload);
        } else {
            console.log('Update Product Error: Product index not found.');
        }
    },
    setIndustries(state, payload) {
        state.industries = payload;
    },
    updateIndustry(state, payload) {
        let industryIndex = state.industries.findIndex(
            (industry) => industry.industryId === payload.industryId
        );

        if (industryIndex > -1) {
            Vue.set(state.industries, industryIndex, payload);
        } else {
            console.log('Update Industry Error: Industry index not found.');
        }
    },
    setProspectSources(state, payload) {
        state.prospectSources = payload;
    },
    updateProspectSource(state, payload) {
        let prospectSourceIndex = state.prospectSources.findIndex(
            (prospectSource) =>
                prospectSource.prospectSourceTypeId ===
                payload.prospectSourceTypeId
        );

        if (prospectSourceIndex > -1) {
            Vue.set(state.prospectSources, prospectSourceIndex, payload);
        } else {
            console.log(
                'Update Prospect Source Error: Prospect Source index not found.'
            );
        }
    },
    setTags(state, payload) {
        state.tags = payload;
    },
    updateTag(state, payload) {
        let tagIndex = state.tags.findIndex(
            (tag) => tag.tagId === payload.tagId
        );

        if (tagIndex > -1) {
            Vue.set(state.tags, tagIndex, payload);
        } else {
            console.log('Update Tag Error: Tag index not found.');
        }
    },
    createBusinessTag(state, payload) {
        let businessIndex = state.businesses.findIndex(
            (business) => business.businessId === payload.businessId
        );

        if (businessIndex > -1) {
            if (state.businesses[businessIndex].businessTags == null) {
                state.businesses[businessIndex].businessTags = [];
            }
            state.businesses[businessIndex].businessProducts = [
                ...state.businesses[businessIndex].businessTags,
                payload,
            ];
        } else {
            console.log('Create Business Tag Error: Business index not found.');
        }
    },
    updateBusinessTag(state, payload) {
        let businessIndex = state.businesses.findIndex(
            (business) => business.businessId === payload.businessId
        );

        if (
            businessIndex > -1 &&
            state.businesses[businessIndex].businessTags
        ) {
            let businessTagIndex = state.businesses[
                businessIndex
            ].businessTags.findIndex(
                (businessTag) =>
                    businessTag.businessTagId === payload.businessTagId
            );

            Vue.set(
                state.businesses[businessIndex].businessTags,
                businessTagIndex,
                payload
            );
        } else {
            console.log('Update Business Tag Error: Business index not found.');
        }
    },
    setBusinesses(state, payload) {
        state.businesses = payload;
    },
    updateBusiness(state, payload) {
        let businessIndex = state.businesses.findIndex(
            (business) => business.businessId === payload.businessId
        );

        if (businessIndex > -1) {
            Vue.set(state.businesses, businessIndex, payload);
        } else {
            console.log('Update Business Error: Business index not found.');
        }
    },
    createBusinessProduct(state, payload) {
        let businessIndex = state.businesses.findIndex(
            (business) => business.businessId === payload.businessId
        );

        if (businessIndex > -1) {
            if (state.businesses[businessIndex].businessProducts == null) {
                state.businesses[businessIndex].businessProducts = [];
            }
            state.businesses[businessIndex].businessProducts = [
                ...state.businesses[businessIndex].businessProducts,
                payload,
            ];
        } else {
            console.log(
                'Create Business Product Error: Business index not found.'
            );
        }
    },
    updateBusinessProduct(state, payload) {
        let businessIndex = state.businesses.findIndex(
            (business) => business.businessId === payload.businessId
        );

        if (
            businessIndex > -1 &&
            state.businesses[businessIndex].businessProducts
        ) {
            let businessProductIndex = state.businesses[
                businessIndex
            ].businessProducts.findIndex(
                (businessProduct) =>
                    businessProduct.businessProductId ===
                    payload.businessProductId
            );

            Vue.set(
                state.businesses[businessIndex].businessProducts,
                businessProductIndex,
                payload
            );
        } else {
            console.log(
                'Update Business Product Error: Business index not found.'
            );
        }
    },
    createBusinessContact(state, payload) {
        let businessIndex = state.businesses.findIndex(
            (business) => business.businessId === payload.businessId
        );

        if (businessIndex > -1) {
            if (state.businesses[businessIndex].businessContacts == null) {
                state.businesses[businessIndex].businessContacts = [];
            }
            state.businesses[businessIndex].businessContacts = [
                ...state.businesses[businessIndex].businessContacts,
                payload,
            ];
        } else {
            console.log(
                'Create Business Contact Error: Business index not found.'
            );
        }
    },
    updateBusinessContact(state, payload) {
        let businessIndex = state.businesses.findIndex(
            (business) => business.businessId === payload.businessId
        );

        if (
            businessIndex > -1 &&
            state.businesses[businessIndex].businessContacts
        ) {
            let businessContactIndex = state.businesses[
                businessIndex
            ].businessContacts.findIndex(
                (businessContact) =>
                    businessContact.businessContactId ===
                    payload.businessContactId
            );

            Vue.set(
                state.businesses[businessIndex].businessContacts,
                businessContactIndex,
                payload
            );
        } else {
            console.log(
                'Update Business Contact Error: Business index not found.'
            );
        }
    },
    setOrphanedMerchants(state, payload) {
        state.orphanedMerchants = payload;
    },
    setSnackbarMessage(state, payload) {
        state.snackbarMessage = payload;
    },
    addToPersist(state, payload) {
        Vue.set(state.persist, payload.key, payload.value);
    },
    setUserPermissions(state, payload) {
        state.userPermissions = payload;
    },
    setUserPreferences(state, payload) {
        console.log(payload);

        payload.forEach((item) => {
            Vue.set(
                state.userPreferences,
                item.preferenceKey,
                item.preferenceValue
            );
        });
    },
    setUserAttributes(state, payload) {
        payload.forEach((item) => {
            Vue.set(state.user.cognitoUser.attributes, item.Name, item.Value);
        });
    },
    setLastRequestTime(state, payload) {
        state.lastRequestTime[payload.name] = payload.value;
    },
    setSalespeople(state, payload) {
        if (payload.length > 0) {
            let businessIndex = state.businesses.findIndex(
                (business) => business.businessId === payload[0].businessId
            );

            if (businessIndex > -1) {
                let primarySalesperson = payload.length > 0 ? payload[0] : null;
                let secondarySalesperson =
                    payload.length > 1 ? payload[1] : null;

                if (primarySalesperson) {
                    Vue.set(
                        state.businesses[businessIndex],
                        'primarySalesperson',
                        primarySalesperson
                    );
                }

                if (secondarySalesperson) {
                    Vue.set(
                        state.businesses[businessIndex],
                        'secondarySalesperson',
                        secondarySalesperson
                    );
                }
            } else {
                console.log('Set Salespeople Error: Business index not found.');
            }
        } else {
            console.log('Set Salespeople Error: No Salespeople found');
        }
    },
};
